import React, { Component } from 'react';
import { connect } from 'react-redux';
import { open_alertpopup } from '../../actions';


class AlertPopup extends Component {
    render() {
        const { alertOpen, children, open_alertpopup, type, maxWidth } = this.props;
        const closeType = `cd-popup-close ${type === 'imgOnly' ? 'cd-popup-close-white' : ''}`;
        return (
            <div
                role="alert"
                className={`cd-popup ${alertOpen ? 'is-visible' : ''}`}
            >
                <div className="cd-popup-container" style={{ maxWidth: maxWidth }}>
                    {children}
                    <button
                        className={closeType}
                        onClick={() => { open_alertpopup(false) }}
                    ></button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    return {
        alertOpen: settings.alertOpen
    }
}

export default connect(mapStateToProps, { open_alertpopup })(AlertPopup);