import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import ReactLoading from "react-loading";

// import IntlMessages from '../../../util/IntlMessages';

import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import AlertPopup from '../../../components/AlertPopup/AlertPopup'
import SwiperList from '../../../components/SwiperList/SwiperList';
import GalleryThumbs from '../../../components/SwiperList/GalleryThumbs';

import IntlMessages from '../../../util/IntlMessages';


import productData from '../../../data/products.json';
import { open_alertpopup, set_meta } from '../../../actions';

import { apiGetProductList, apiGetProductOne } from '../../../apis';



class Product extends Component {
    state = {
        data: null,
        dataList: null,
        loading: true
    }

    componentDidMount() {
        this.props.open_alertpopup(false);
        this.fetchProductOneApi();
    }

    componentDidUpdate(newProps){
       if(newProps.match.params.id !== this.props.match.params.id){
            this.setState({loading: true});
            this.fetchProductOneApi();
       }
      if(newProps.locale.locale !== this.props.locale.locale){
	  this.setMeta();
       }
    }

    setMeta = ()=>{
	const webLocation = window.location;
        const { data } = this.state;
        const { locale } = this.props;
	this.props.set_meta({
		title: data[locale.locale][0].Title,
		description: data[locale.locale][0].Description,
		image: data[locale.locale][0].Key_Image,
		url: webLocation.href
	    });
    }

    fetchProductOneApi = () => {
        const { match, locale } = this.props;
        axios.all([
            apiGetProductOne(match.params.id),
            apiGetProductList(),
        ]).then(axios.spread((...res) => {
            const resArr = _.map(res, 'data');
            this.setState({
                data: resArr[0].Body,
                dataList: resArr[1].Body,
                loading: false
            },()=> this.setMeta())
                        // if (resArr[0].Message === "Success." && resArr[1].Message === "Success.") {
            //     this.setState({
            //         data: resArr[0].Body,
            //         dataList: resArr[1].Body,
            //         loading: false
            //     })
            // } else {
            //     this.setState({
            //         data: {
            //             data: resArr[0].Message,
            //             dataList: resArr[1].Message
            //         },
            //         loading: false
            //     })
            // }
        })).catch(err => {
            console.log(err)
        })
    }

    openProductImgPopup = () => {
        const { alertOpen, open_alertpopup } = this.props;
        open_alertpopup(!alertOpen)
    }

    renderAlertContent(imgUrl) {
        const prouctGallery = {
            lazyLoad: true,
            showPlayButton: false,
            showFullscreenButton: false,
            showThumbnails: false
        }

        return (
            <AlertPopup type="imgOnly" maxWidth={650}>
                <GalleryThumbs productImg={imgUrl} prouctGallery={prouctGallery} />
            </AlertPopup>
        )
    }

    render() {
        const { loading, data, dataList } = this.state;
        const { history, match, locale } = this.props;

        const filterProductImg = !data ? null : _.filter([data[locale.locale][0].Image1, data[locale.locale][0].Image2, data[locale.locale][0].Image3, data[locale.locale][0].Image4, data[locale.locale][0].Image5], item => item !== "")

        const productLike = !data ? null : _.filter(dataList[locale.locale], item => item.Catalog_ID === parseInt(data[locale.locale][0].Catalog_ID))

        const specArr = !data ? null : data[locale.locale][0].Specification.split('\r\n');


        const prouctGallery = {
            lazyLoad: true,
            showNav: false,
            showPlayButton: false,
            showFullscreenButton: false,
            slideOnThumbnailOver: true
        }


        return (
           <Fragment>
                {
                    !data ? null : <Breadcrumbs router={data[locale.locale][0].Name} />
                }
                <SearchBar match={match} history={history} />
                <section>
                    {
                        loading ? <div className="loading" style={{ padding: '160px 0' }}>
                            <ReactLoading type="bubbles" color="#b06b0d" />
                        </div> : <div className="l__container">
                                <div className="productDetail">
                                    {
                                        !data ? <div className="productDetail__noProduct">
                                            <span>查無產品</span>
                                            <Link to="/product" className="l__moreLinkBtn m-auto">
                                                {<IntlMessages id="back.product" />}
                                            </Link>
                                        </div>
                                            : <Fragment>
                                                <Link to="/product" className="l__backBtn">{<IntlMessages id="back.product" />}</Link>
                                                <div className="productDetail__body">
                                                    <div className="productDetail__productImg">
                                                        <GalleryThumbs
                                                            openProductImgPopup={this.openProductImgPopup}
                                                            prouctGallery={prouctGallery}
                                                            productImg={filterProductImg} />
                                                    </div>
                                                    <div className="productDetail__productContent">
                                                        <div className="productDetail__section">
                                                            <div className="productDetail__titleName">
                                                                {data[locale.locale][0].Name}
                                                            </div>
                                                            {
                                                                _.map(_.split(data[locale.locale][0].Note, '\r\n'), (item, idx) => <p className="text-primary" key={idx}>{item}</p>)
                                                            }
                                                        </div>
                                                        <div className="productDetail__section">
                                                            <div className="productDetail__label">{<IntlMessages id="product.feature" />}</div>
                                                            <ul className="productDetail__infoList productDetail__infoList--hasType">
                                                                {
                                                                    _.map(_.split(data[locale.locale][0].Feature, '\r\n'), (item, idx) => <li key={idx}>{item}</li>)
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="productDetail__section">
                                                            <div className="productDetail__label">{<IntlMessages id="product.specs" />}</div>
                                                            <ul className="productDetail__infoList">
                                                                {
                                                                    _.times(6, n => <li key={n}><IntlMessages id={`product.featureItem${n + 1}`} /> {specArr[n]}</li>)
                                                                }
                                                                <li>
                                                                    {
                                                                        _.map(_.split(data[locale.locale][0].Certificate, '\r\n'), (item, idx) => <p key={idx} className="text-primary">{item}</p>)
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {
                                                            data[locale.locale][0].Notice === "" ?
                                                                null :
                                                                <div className="productDetail__section">
                                                                    <div className="productDetail__label">{<IntlMessages id="product.notes" />}</div>
                                                                    <ul className="productDetail__infoList">
                                                                        {
                                                                            _.map(_.split(data[locale.locale][0].Notice, '\r\n'), (item, idx) => <p key={idx} className="text-primary">{item}</p>)
                                                                        }
                                                                    </ul>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                            </div>
                    }
                </section>
                <section>
                    {
                        loading || !data ? null
                            : <div className="l__container">
                                <SectionTitle title={<IntlMessages id="product.like" />} />
                                <div className="productLikeList">
                                    <SwiperList products={productLike} />
                                </div>
                            </div>
                    }
                </section>
                {this.renderAlertContent(filterProductImg)}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { alertOpen, locale } = settings;
    return { alertOpen, locale }
}

export default connect(mapStateToProps, { open_alertpopup, set_meta })(Product);
