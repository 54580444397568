import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import ReactLoading from "react-loading";

import IntlMessages from '../../util/IntlMessages';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../components/SearchBar/SearchBar';
import ProductItem from '../../components/ProductItem/ListItem'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import AlertPopup from '../../components/AlertPopup/AlertPopup'
import ProductDetail from './DetailPage/index';
import SwiperList from '../../components/SwiperList/SwiperList';

import productData from '../../data/products.json';
import { open_alertpopup, change_product_tab } from '../../actions';

import { apiGetProductCatalog, apiGetProductList, apiGetProductSearch } from '../../apis';

class Product extends Component {
    state = {
        currentCatalog: "",
        currentCatalogBrother: "",
        productCurrent: {},
        data: null,
        searchData: null,
        loading: true
    }

    componentDidMount() {
        this.fetchProductApi();
        this.props.open_alertpopup(false);
        this.props.match.params.id && this.fetchProductSearchApi(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locale.locale !== this.props.locale.locale) {
            this.setState({
                currentCatalog: this.state.currentCatalogBrother,
                currentCatalogBrother: this.state.currentCatalog,
            })
        }
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.fetchProductSearchApi(nextProps.match.params.id);
        }
    }

    fetchProductApi = () => {
        const { locale } = this.props;
        axios.all([
            apiGetProductCatalog(),
            apiGetProductList(),
        ]).then(axios.spread((...res) => {
            const resArr = _.map(res, 'data');
            if (resArr[0].Message === "Success." && resArr[1].Message === "Success.") {
                this.setState({
                    currentCatalog: resArr[0].Body[locale.locale][0]["ID"],
                    currentCatalogBrother: resArr[0].Body[locale.locale][0]["Brother_ID"],
                    data: {
                        catalog: resArr[0].Body,
                        product: resArr[1].Body
                    },
                    loading: false
                })
            } else {
                this.setState({
                    data: {
                        catalog: resArr[0].Message,
                        product: resArr[1].Message
                    },
                    loading: false
                })
            }
        })).catch(err => {
            console.log(err)
        })
    }

    fetchProductSearchApi = (Keyword) => {
        this.setState({ loading: true })
        apiGetProductSearch({
            Keyword
        }).then(({ data }) => {
            if (data.Message === "Success.") {
                this.setState({
                    searchData: data.Body,
                    loading: false
                })
            } else {
                this.setState({
                    searchData: null,
                    loading: false
                })
            }
        })
    }

    changeTabClick = (ID, Brother_ID) => {
        this.props.change_product_tab(ID);
        this.setState({
            currentCatalog: ID,
            currentCatalogBrother: Brother_ID,
        })
    }

    saveProductDetail = product => {
        this.setState({
            productCurrent: product
        })
    }

    clickProductLink = (e, id) => {
        const clickClassName = e.target.getAttribute("class");
        if (clickClassName === "productContent__list__search cd-popup-trigger") return;
        this.props.history.push({
            pathname: `/product_detail/${id}`,
        })
    }

    renderAlertContent() {
        const { productCurrent } = this.state;
        const specArr = !productCurrent.Specification ? "" : productCurrent.Specification.split('\r\n');

        return (
            <AlertPopup>
                <div className="cd-popup-content">
                    <div className="productDetail productDetail__popup">
                        <div className="productDetail__body">
                            <div className="productDetail__productImg">
                                <img src={productCurrent.Image1} alt={productCurrent.Name} />
                            </div>
                            <div className="productDetail__productContent">
                                <div className="productDetail__titleName">
                                    {productCurrent.Name}
                                </div>
                                <Link
                                    to={`/product_detail/${productCurrent.ID}`}
                                    component={ProductDetail}
                                    className="productDetail__detailLink">{<IntlMessages id="product.more" />}</Link>
                                <div className="productDetail__label">{<IntlMessages id="product.feature" />}</div>
                                <ul className="productDetail__infoList">
                                    {
                                        _.times(6, n => <li key={n}><IntlMessages id={`product.featureItem${n + 1}`} /> {specArr[n]}</li>)
                                    }
                                </ul>
                                {
                                    _.map(_.split(productCurrent.Certificate, '\r\n'), (item, idx) => <p key={idx} className="text-primary">{item}</p>)
                                }
                                {/* _.map(_.split(productCurrent.Specification, '\r\n'), (item, idx) => <li key={idx}>{item}</li>) */}

                            </div>
                        </div>
                    </div>
                </div>
            </AlertPopup>
        )
    }

    render() {
        const { data, loading, currentCatalog, searchData } = this.state;
        const { history, match, productTabCurrent, locale } = this.props;
        //const productFilter = !data ? null : _.filter(data.product[locale.locale], item => item.Catalog_ID === parseInt(currentCatalog))
	const productFilter = !data ? null : _.sortBy(_.filter(data.product[locale.locale], item => item.Catalog_ID === parseInt(currentCatalog)), o=>o.Sort);
        return (
            <Fragment>
                <Breadcrumbs router={<IntlMessages id="product.banner.title" />} />
                <SearchBar match={match} history={history} />
                <section>
                    {
                        loading ? <div className="loading" style={{ padding: '160px 0' }}>
                            <ReactLoading type="bubbles" color="#b06b0d" />
                        </div> : <div className="l__container">
                                <Link to="/" className="l__backBtn d-block d-lg-none">{<IntlMessages id="back.index" />}</Link>
                                {match.params.id !== undefined ?
                                    <Fragment>
                                        <SectionTitle
                                            title={<IntlMessages id="search.result" />}
                                        />
                                        <p className="l__subTitle">
                                            {

                                                locale.locale === 'CH' ?
                                                    `找到 ${!searchData ? 0 : searchData.length} 筆符合項目` :
                                                    `${!searchData ? 0 : searchData.length} results find for:`
                                            }
                                        </p>
                                    </Fragment>
                                    :
                                    <SectionTitle
                                        title={<IntlMessages id="product.banner.title" />}
                                        subTitle={<IntlMessages id="product.subtitle" />}
                                    />
                                }
                                <div className="productContent">
                                    {
                                        match.params.id !== undefined ?
                                            null :
                                            <ul className="l__leftMenu h-auto">
                                                {
                                                    _.map(data.catalog[locale.locale], item => {
                                                        return <li className="l__leftMenu__item" key={item.ID}>
                                                            <span className={`l__leftMenu__link ${currentCatalog === item.ID ? 'l__leftMenu__link--active' : ""}`}
                                                                onClick={() => { this.changeTabClick(item.ID, item.Brother_ID) }}>
                                                                {item.Name}
                                                                <i></i>
                                                            </span>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                    }
                                    <div className={`productContent__list ${match.params.id !== undefined ? 'w-100' : ''}`}>
                                        <div className="row">
                                            {
                                                searchData !== null || !match.params.id ? null :
                                                    <div className="col-12">
                                                        <div className="noSearchContent">
                                                            {
                                                                locale.locale === 'CH' ?
                                                                    <p>很抱歉，找不到「{match.params.id}」相關結果</p> :
                                                                    <p>No results find for: {match.params.id}</p>
                                                            }

                                                        </div>
                                                    </div>
                                            }
                                            {
                                                _.map(match.params.id ? searchData : productFilter, product => {
                                                    return (
                                                        <div
                                                            className="col-6 col-lg-3 cursor-pointer"
                                                            key={product.ID}
                                                            onClick={(e) => (this.clickProductLink(e, product.Name))}
                                                        >
                                                            <ProductItem
                                                                id={product.ID}
                                                                img={product.Image1}
                                                                title={product.Name}
                                                                // title={`${product.Catalog === "PAPER" ? '' : product.Catalog + ' '}${product.Name}`}
                                                                saveProductDetail={() => { this.saveProductDetail(product) }}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
                {
                    match.params.id === undefined ? null
                        : (loading || !data ? null : <section>
                            <div className="l__container">
                                <SectionTitle title="您可能在尋找" />
                                <div className="productLikeList">
                                    <SwiperList products={!data ? null : data.product[locale.locale]} />
                                </div>
                            </div>
                        </section>)
                }
                {this.renderAlertContent()}
            </Fragment>
        );
    }
}


const mapStateToProps = ({ settings }) => {
    const { width, productTabCurrent, locale } = settings;
    return { width, productTabCurrent, locale }
}

export default connect(
    mapStateToProps,
    { open_alertpopup, change_product_tab }
)(Product);

