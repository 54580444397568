import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ReactLoading from "react-loading";
import axios from 'axios';

import IntlMessages from '../../../util/IntlMessages';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import PageBanner from '../../../components/PageBanner/PageBanner';

import BannerNewsPC from '../../../assets/images/pc/image_news.png';
import BannerNewsM from '../../../assets/images/mobile/image_news.png';


import { apiGetNewList, apiGetNewOne } from '../../../apis';

class NewsDetail extends Component {
    state = {
        data: {},
        loading: true
    }

    componentDidMount() {
        this.fetchNewsOneApi(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locale.locale === this.props.locale.locale) {
            this.fetchNewsOneApi(nextProps.match.params.id);
        } else {
            this.props.history.push(`/news_detail/${this.state.data.news[this.props.locale.locale][0].Brother_ID}`)
        }
    }

    fetchNewsOneApi = ID => {
        const { locale } = this.props
        this.setState({ loading: true })
        axios.all([
            apiGetNewList(),
            apiGetNewOne(ID),
        ]).then(axios.spread((...res) => {
            const resArr = _.map(res, 'data');
            if (resArr[0].Message === "Success." && resArr[1].Message === "Success.") {
                const newsListArr = _.map(resArr[0].Body[locale.locale], item => item.ID);
                this.setState({
                    data: {
                        news: resArr[1].Body,
                        newsListArr
                    },
                    loading: false
                })
            } else {
                this.setState({
                    data: {
                        news: null,
                    },
                    loading: false
                })
            }
        })).catch(err => {
            console.log(err)
        })
    }

    render() {
        const { data, loading } = this.state;
        const { match, locale } = this.props;

        const newsListIndex = !data.newsListArr ? null : data.newsListArr.indexOf(parseInt(match.params.id));

        return (
            <Fragment>
                <Breadcrumbs router={<IntlMessages id="news.banner.title" />} />
                <section>
                    <PageBanner
                        BannerAboutPC={BannerNewsPC}
                        BannerAboutM={BannerNewsM}
                        BannerTitle={<IntlMessages id="news.banner.title" />} />
                </section>
                <section>
                    {
                        loading ? <div className="loading" style={{ padding: '160px 0' }}>
                            <ReactLoading type="bubbles" color="#b06b0d" />
                        </div> : (
                                data === null ? <div className="loading" style={{ padding: '160px 0' }}>
                                    查無資料
                                </div>
                                    : <div className="l__container l__container--page">
                                        <div className="newsDetail">
                                            <Link to="/news" className="l__backBtn">{<IntlMessages id="back.news" />}</Link>
                                            <div className="newsDetail__body">
                                                <div className="newsDetail__title">{data.news[locale.locale][0].Title}</div>
                                                <p className="newsDetail__date">{data.news[locale.locale][0].Date}</p>
                                                <div className="newsDetail__content">
                                                    <p dangerouslySetInnerHTML={{ __html: data.news[locale.locale][0].Content }}></p>
                                                    {
                                                        _.times(3, n => {
                                                            if (data.news[locale.locale][0][`Picture${n + 1}`] === "" || data.news[locale.locale][0][`Picture${n + 1}`] === null) return null
                                                            return (
                                                                <p className="newsDetail__picture" key={n}>
                                                                    <img src={data.news[locale.locale][0][`Picture${n + 1}`]} alt={data.news[locale.locale][0][`Picture${n + 1}_Title`]} />
                                                                    <span className="newsDetail__picture__name">{data.news[locale.locale][0][`Picture${n + 1}_Title`]}</span>
                                                                    <span className="newsDetail__picture__content">{data.news[locale.locale][0][`Picture${n + 1}_Content`]}</span>
                                                                </p>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                            <div className="newsDetail__bottom">
                                                {
                                                    newsListIndex === 0 ? <div></div> :
                                                        <Link
                                                            className="newsDetail__prev"
                                                            to={`/news_detail/${data.newsListArr[newsListIndex - 1]}`}>
                                                            {<IntlMessages id="news.prev" />}
                                                        </Link>
                                                }
                                                {
                                                    newsListIndex === data.newsListArr.length - 1 ? <div></div> :
                                                        <Link
                                                            className="newsDetail__next"
                                                            to={`/news_detail/${data.newsListArr[newsListIndex + 1]}`}>
                                                            {<IntlMessages id="news.next" />}
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            )
                    }
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ settings }) => {
    const { locale } = settings;
    return { locale }
}

export default connect(
    mapStateToProps,
    null
)(NewsDetail);