import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import ScrollToTop from 'react-router-scroll-top';
import ScrollUpButton from "react-scroll-up-button";
// import { initGA, logPageView } from './util/analytics';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from './lngProvider';

import { updateWindowWidth, set_meta } from './actions';
import history from './History';

import './assets/vendors/styles';
import topImg from './assets/images/pc/back_to_top.png';

import Header from './components/Header/HeaderPage';
import Footer from './components/Footer/FooterPage';

import Home from './pages/Home/index'
import About from './pages/About/index'
import Material from './pages/Material/index'
import Certification from './pages/Certification/index'
import Product from './pages/Product/index'
import ProductDetail from './pages/Product/DetailPage/index'
import News from './pages/News/index'
import NewsDetail from './pages/News/DetailPage/index'
import Contactus from './pages/Contactus/index'
// import SearchPage from './pages/SearchPage/index'
import NoMatch from './pages/NoMatch/index'

import ReactGA from "react-ga";

ReactGA.initialize("UA-78270455-3");
history.listen(location => {
  ReactGA.set({ page: location.pathname + location.hash });
  ReactGA.pageview(location.pathname + location.hash);
});

class App extends Component {

    componentDidMount(newProps) {
        ReactGA.pageview(window.location.pathname);
        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth)
        });
	history.listen((listener)=> {
	   const webDomain = window.location.origin;
		console.log(webDomain + '/' + listener.hash);
	   this.props.set_meta({
		url: webDomain + '/' + listener.hash
	   })
	});
    }

    componentDidUpdate(newProps){
	if(history.location.hash.indexOf("product_detail") > -1) return;
	if(this.props.locale.locale !== newProps.locale.locale){
		this.props.set_meta({})
	}
    }

    render() {
        const { locale, meta } = this.props;
        const currentAppLocale = AppLocale[locale.locale];
        return (
<Fragment>
	     <Helmet>
   	        <title>{meta.title}</title>
		<meta property="description" content={meta. description} />
	        <meta property="og:url" content={meta.url} />
	        <meta property="og:title" content={meta.title}/>
	        <meta property="og:description" content={meta. description} />
	        <meta property="og:image" content={meta.image} />
	    </Helmet>

            <Router>
                <ScrollToTop>
                    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <div className="wrap">
                            <Header />
                            <div className="l__content">
                                <Switch history={history}>
                                    <Route path="/" exact component={Home} />
                                    <Route path="/about" component={About} />
                                    <Route path="/material" component={Material} />
                                    <Route path="/certification" component={Certification} />
                                    <Route path="/product" component={Product} />
                                    <Route path="/product_detail/:id" component={ProductDetail} />
                                    <Route path="/news" component={News} />
                                    <Route path="/news_detail/:id" component={NewsDetail} />
                                    <Route path="/contactus" component={Contactus} />
                                    <Route path="/search/:id" component={Product} />
                                    <Redirect from='' to="/" />
                                    <Route component={NoMatch} />
                                </Switch>
                            </div>
                            <Footer />
                            <ScrollUpButton ContainerClassName="goTop" TransitionClassName="goTop">
                                <img src={topImg} alt="" />
                            </ScrollUpButton>
                        </div>
                    </IntlProvider>
                </ScrollToTop>
            </Router>
</Fragment>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { locale, meta } = settings;
    return { locale, meta }
}

export default connect(
    mapStateToProps,
    { updateWindowWidth, set_meta }
)(App);