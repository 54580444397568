import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import IntlMessages from '../../util/IntlMessages';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PageBanner from '../../components/PageBanner/PageBanner';
import ListItem from './components/ListItem';
import ReactLoading from "react-loading";

import BannerNewsPC from '../../assets/images/pc/image_news.png'
import BannerNewsM from '../../assets/images/mobile/image_news_m.png'

import { apiGetNewList, apiGetProductHotList } from '../../apis';

class News extends Component {
    state = {
        data: {},
        loading: true
    }

    componentDidMount() {
        this.fetchNewsApi();
    }

    fetchNewsApi = () => {
        axios.all([
            apiGetNewList(),
            apiGetProductHotList(),
        ]).then(axios.spread((...res) => {
            const resArr = _.map(res, 'data');
            this.setState({
                data: {
                    news: resArr[0].Body,
                    hotProduct: resArr[1].Body,
                },
                loading: false
            })
        })).catch(err => {
            console.log(err)
        })
    }

    render() {
        const { data, loading } = this.state;
        const { locale } = this.props;
        return (
            <Fragment>
                <Breadcrumbs router={<IntlMessages id="news.banner.title" />} />
                <section>
                    <PageBanner
                        BannerAboutPC={BannerNewsPC}
                        BannerAboutM={BannerNewsM}
                        BannerTitle={<IntlMessages id="news.banner.title" />} />
                </section>
                <section>
                    {
                        loading ? <div className="loading" style={{ padding: '160px 0' }}>
                            <ReactLoading type="bubbles" color="#b06b0d" />
                        </div> : <div className="l__container l__container--page">
                                <div className="newsContent">
                                    <ul className="newsContent__list">
                                        {
                                            _.map(_.orderBy(data.news[locale.locale], o => o.Sort), item => {
                                                const { ID, Title, Date } = item
                                                return (
                                                    <li className="newsContent__item" key={ID}>
                                                        <Link to={`/news_detail/${ID}`} className="newsContent__link">
                                                            <ListItem
                                                                title={Title}
                                                                date={Date} />
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                        {/* <li className="newsContent__moreBtn">{<IntlMessages id="news.more" />}</li> */}
                                    </ul>
                                    <div className="hotProduct">
                                        <div className="hotProduct__title">
                                            <IntlMessages id="news.hot_items" />
                                        </div>
                                        {
                                            _.map(data.hotProduct[locale.locale], item => {
                                                const { ID, Image1, Name } = item
                                                return (
                                                    <Link to={`product_detail/${ID}`} key={ID} className="hotProduct__link">
                                                        <img src={Image1} alt={Name} />
                                                        <div className="hotProduct__name">
                                                            {Name}
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </section>
            </Fragment>
        )
    }

}

const mapStateToProps = ({ settings }) => {
    const { locale } = settings;
    return { locale }
}

export default connect(
    mapStateToProps,
    null
)(News);