import appLocaleData from 'react-intl/locale-data/zh';
import zhMessages from '../locales/zh_TW.json';

const ZhLan = {
  messages: {
    ...zhMessages
  },
  locale: 'zh-TW',
  data: appLocaleData
};
export default ZhLan;
