import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { open_pdfpopup } from '../../actions';
import _ from 'lodash';
import ReactLoading from "react-loading";
import axios from 'axios';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import CustomScrollbars from '../../util/CustomScrollbars';


import IntlMessages from '../../util/IntlMessages';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PageBanner from '../../components/PageBanner/PageBanner';
import ListItem from './Components/ListItem';
// import AlertPopup from '../../components/AlertPopup/AlertPopup';
import AlertPopupPDF from '../../components/AlertPopup/AlertPopupPDF';
import PdfViewer from '../../components/PdfViewer/PdfViewer';


import BannerCertificationPC from '../../assets/images/pc/image_certificate.png';
import BannerCertificationtM from '../../assets/images/mobile/image_certificate_m.png';

import data from '../../data/certificate.json';
import { apiGetCertificateCatalog, apiGetCertificateList } from '../../apis';

import { certificateExportsFile, certificatePPExportsFile } from '../../util/ExportFile';

class Certification extends Component {
    state = {
        isFixed: false,
        pdfInfo: {},
        data: null,
        loading: true
    }

    leftMenuRef = React.createRef();

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        this.fetchCertificateApi();
        this.props.open_pdfpopup(false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true)
    }

    fetchCertificateApi = () => {
        axios.all([
            apiGetCertificateCatalog(),
            apiGetCertificateList(),
        ]).then(axios.spread((...res) => {
            const resArr = _.map(res, 'data');
            if (resArr[0].Message === "Success." && resArr[1].Message === "Success.") {
                this.setState({
                    data: {
                        catalog: resArr[0].Body,
                        certificate: resArr[1].Body
                    },
                    loading: false
                })
            } else {
                this.setState({
                    data: {
                        catalog: resArr[0].Message,
                        product: resArr[1].Message
                    },
                    loading: false
                })
            }
        })).catch(err => {
            console.log(err)
        })
    }

    handleScroll = () => {
        const leftMenuTop = !this.leftMenuRef.current ? null : this.leftMenuRef.current.offsetTop;
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (this.leftMenuRef.current && scrollTop > leftMenuTop) {
            this.setState({ isFixed: true })
        } else {
            this.setState({ isFixed: false })
        }

        if (window.scrollY >= 16000) {
            this.setState({ menuHeight: window.scrollY - 13465 })
        } else {
            this.setState({ menuHeight: 0 })
        }
    }

    renderAlertPDF() {
       const { pdfUrl, name } = this.state.pdfInfo;
       return (
            <AlertPopupPDF titleName={name}>
                <PdfViewer pdfFile={pdfUrl} />
            </AlertPopupPDF>
            // <AlertPopup type="imgOnly" maxWidth={480}>
            //     <img src={pdfUrl} alt={name} />
            // </AlertPopup>
        )
    }

    openPdfRender = (pdfUrl, name) => {
        if (pdfUrl === "") return;
        this.setState({
            pdfInfo: { pdfUrl, name }
        }, () => {
            this.props.open_pdfpopup(true);
        })
    }

    // openPdfRender = (pdfUrl, name) => {
    //     const { alertOpen, open_alertpopup } = this.props;
    //     if (pdfUrl === "") return;
    //     this.setState({
    //         pdfInfo: { pdfUrl, name }
    //     }, () => {
    //         open_alertpopup(!alertOpen);
    //     })
    // }

    render() {
        const leftMenuClassName = `l__leftMenu ${this.state.isFixed ? 'l__leftMenu--fixed' : ''}`;
        const { loading, data } = this.state;
        const { openPdfPopup, locale } = this.props;
        const menuHeight = 680 - this.state.menuHeight || 680;
        const certificateData = !data ? null : _.map(data.catalog[locale.locale], item => Object.assign({
            ID: item.ID,
            catalogName: item.Name,
            certificate: _.sortBy(_.filter(data.certificate[locale.locale], item2 => item2.Catalog_ID === parseInt(item.ID) || item2.Catalog_ID === parseInt(item.ID)),o=>o.Sort)
        }))



        return (
            <Fragment>
                <Breadcrumbs router={<IntlMessages id="certification.banner.title" />} />
                <section>
                    <PageBanner
                        BannerAboutPC={BannerCertificationPC}
                        BannerAboutM={BannerCertificationtM}
                        BannerTitle={<IntlMessages id="certification.banner.title" />} />
                </section>
                <section>
                    {
                        loading ? <div className="loading" style={{ padding: '160px 0' }}>
                            <ReactLoading type="bubbles" color="#b06b0d" />
                        </div> : <div className="l__container l__container--page">
                                <Link to="/" className="l__backBtn d-block d-lg-none">{<IntlMessages id="back.index" />}</Link>
                                <div className="certificationContent">
                                    <div className="menuPos" ref={this.leftMenuRef}>
                                        <CustomScrollbars
                                            className={leftMenuClassName}
                                            style={{ width: '300px', maxHeight: menuHeight }}>
                                            <div>

                                                {
                                                    _.map(certificateData, item => {
                                                        return <Fragment key={item.ID}>
                                                            <div className="l__font24 text-primary">{item.catalogName}</div>
                                                            <Scrollspy
                                                                className="mt-24"
                                                                offset={-121}
                                                                items={_.map(item.certificate, item2 => `certificate${item2.ID}`)}
                                                                currentClassName="l__leftMenu__item--active"
                                                            >
                                                                {
                                                                    _.map(item.certificate, item2 => {
                                                                        return <li className={`l__leftMenu__item`} key={item2.ID}>
                                                                            <AnchorLink offset={120} href={`#certificate${item2.ID}`}>{item2.Name}</AnchorLink>
                                                                        </li>
                                                                    })
                                                                }
                                                            </Scrollspy>
                                                        </Fragment>
                                                    })
                                                }
                                                {/* <div className="l__font24 text-primary">{<IntlMessages id="certification.pla" />}</div> */}
                                                {/* <Scrollspy
                                                    offset={-121}
                                                    className="mt-24"
                                                    items={['section1', 'section2', 'section3', 'section4', 'section5', 'section6', 'section7', 'section8', 'section9', 'section10', 'section11', 'section12', 'section_paper1', 'SGS']}
                                                    currentClassName="l__leftMenu__item--active"
                                                >
                                                    {
                                                        _.map(data[locale.locale], (item, idx) => {
                                                            if (item.Catalog === "PLA") {
                                                                return (
                                                                    <li className="l__leftMenu__item" key={item.ID}>
                                                                        {
                                                                            item.ID === "12" ?
                                                                                <AnchorLink offset={120} href={`#section${idx + 1}`}>
                                                                                    {locale.locale === "CH" ? 'USDA認證82%生物基質' : 'USDA Certification'}
                                                                                </AnchorLink>
                                                                                : <AnchorLink offset={120} href={`#section${idx + 1}`}>{item.Name}</AnchorLink>
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <div className="l__font24 text-primary">{<IntlMessages id="certification.paper" />}</div>
                                                    {
                                                        _.map(data[locale.locale], (item, idx) => {
                                                            if (item.Catalog === "PAPER") {
                                                                return (
                                                                    <li className="l__leftMenu__item mt-24" key={item.ID}>
                                                                        <AnchorLink offset={120} href={`#section_paper1`}>{item.Name}</AnchorLink>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <div className="l__font24 text-primary">{<IntlMessages id="certification.pp" />}</div>
                                                    <li className="l__leftMenu__item mt-24">
                                                        <AnchorLink offset={120} href='#SGS'>{locale.locale === "CH" ? 'SGS測試報告' : 'SGS Test Report'}</AnchorLink>
                                                    </li>
                                                </Scrollspy> */}
                                            </div>
                                        </CustomScrollbars>
                                    </div>
                                    <div className="certificationContent__list">
                                        <div className="certificationContent__text">
                                            {<IntlMessages id="certification.p1.content" />}
                                        </div>
                                        {
                                            _.map(certificateData, item => {
                                                return <div className="certificationContent__picList" key={item.ID}>
                                                    <div className="certificationContent__picList__title">{item.catalogName}</div>
                                                    <div className="row">
                                                        {
                                                            _.map(item.certificate, item2 => {
								const expire = item2.Expire === '0000-00-00 00:00:00' ? '' : item2.Expire;
                                                                return <div
                                                                    // className="col-6"
                                                                    className={`col-6 ${item2.Pdf === "" ? "" : 'cursor-pointer'}`}
                                                                    id={`certificate${item2.ID}`}
                                                                    key={item2.ID}
                                                                    // onClick={() => { if (item2.Pdf !== "") this.openPdfRender(item2.Pdf, item2.Name) }}
                                                                >
                                                                    <ListItem
                                                                        img={item2.Image}
                                                                        title={item2.Name}
                                                                        number={item2.Number}
                                                                        deadline={expire}
                                                                        pdf={item2.Pdf === "" ? false : true}
                                                                    />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {/* <div className="certificationContent__picList">
                                            <div className="certificationContent__picList__title">{<IntlMessages id="certification.pla" />}</div>
                                            <div className="row">
                                                {
                                                    _.map(data[locale.locale], (item, idx) => {
                                                        if (item.Catalog === "PLA") {
							    const expire = item2.Expire === '0000-00-00 00:00:00' ? '' : item2.Expire;
                                                            return (
                                                                <div
                                                                    // className="col-6"
                                                                    className={`col-6 ${item.Pdf === "" ? "" : 'cursor-pointer'}`}
                                                                    id={`section${idx + 1}`}
                                                                    key={item.ID}
                                                                    onClick={() => { if (item.Pdf !== "") this.openPdfRender(certificateExportsFile()[item.Pdf], item.Name) }}
                                                                >
                                                                    <ListItem
                                                                        img={certificateExportsFile()[item.Image]}
                                                                        title={item.Name}
                                                                        number={item.Number}
                                                                        deadline={expire}
                                                                        pdf={item.Pdf === "" ? false : true}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="certificationContent__picList">
                                            <div className="certificationContent__picList__title">{<IntlMessages id="certification.paper" />}</div>
                                            <div className="row" id="section_paper1">
                                                {
                                                    _.map(data[locale.locale], (item, idx) => {
                                                        if (item.Catalog === "PAPER") {
						            const expire = item2.Expire === '0000-00-00 00:00:00' ? '' : item2.Expire;
                                                            return (
                                                                <div
                                                                    className={`col-6 ${item.Pdf === "" ? "" : 'cursor-pointer'}`}
                                                                    key={item.ID}
                                                                    onClick={() => { if (item.Pdf !== "") this.openPdfRender(certificateExportsFile()[item.Pdf], item.Name) }}
                                                                >
                                                                    <ListItem
                                                                        img={certificateExportsFile()[item.Image]}
                                                                        title={item.Name}
                                                                        number={item.Number}
                                                                        deadline={expire}
                                                                        pdf={item.Pdf === "" ? false : true}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="certificationContent__picList">
                                            <div className="certificationContent__picList__title">{<IntlMessages id="certification.pp" />}</div>
                                            <div className="row" id="SGS">
                                                {
                                                    _.map(certificatePPExportsFile(), (item, idx) => {
                                                        if (item.indexOf('.pdf') < 0) {
                                                            return (
                                                                <div
                                                                    className="col-6 cursor-pointer"
                                                                    key={idx}
                                                                    onClick={() => { this.openPdfRender(certificateExportsFile()[idx + '_pdf'], idx) }}
                                                                >
                                                                    <ListItem
                                                                        img={certificatePPExportsFile()[idx]}
                                                                        title={idx}
                                                                        pdf={true}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                    }
                </section>
                {openPdfPopup ? this.renderAlertPDF() : null}
                {/* {alertOpen ? this.renderAlertPDF() : null} */}
            </Fragment>
        )
    }
}



const mapStateToProps = ({ settings }) => {
    const { openPdfPopup, locale } = settings;
    return { openPdfPopup, locale }
}

export default connect(mapStateToProps, { open_pdfpopup })(Certification);
