import React, { Component } from 'react';
import IntlMessages from '../../../util/IntlMessages';


class AlertMessage extends Component {
    state = {
        active: false
    }

    componentDidMount() {
        const vm = this;
        setTimeout(function () {
            vm.setState({
                active: true
            })
        }, 100);
        setTimeout(function () {
            vm.closeAlert();
        }, 3000);
    }

    closeAlert = () => {
        const vm = this;
        this.setState({
            active: false
        }, () => {
            setTimeout(function () {
                vm.props.closeAlert();
            }, 500);
        })
    }

    render() {
        const { IsSuccess } = this.props;
        const alertClass = `contactAlert ${!this.state.active ? null : 'contactAlert--active'}`;
        const isSuccess = !IsSuccess ? 'contactAlert--error' : 'contactAlert--success';
        return (
            <div onClick={this.closeAlert} className={`${alertClass} ${isSuccess}`}>
                <div className="contactAlert__body">
                    <div className="contactAlert__title">
                        {!IsSuccess ? <IntlMessages id="contact.form.error.title" /> : <IntlMessages id="contact.form.success.title" />}
                    </div>
                    <p className="contactAlert__text">
                        {!IsSuccess ? <IntlMessages id="contact.form.error.message" /> : <IntlMessages id="contact.form.success.message" />}
                    </p>
                </div>
            </div>
        );
    }
}

export default AlertMessage;