import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from '../NavBar/NavBarPage';

import { open_searchBar } from '../../actions';
import history from '../../History';


class HeaderPage extends Component {
    state = {
        openMenu: false,
        nowLocation: ""
    }

    openMenuClick = e => {
        e.preventDefault();
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    openSearchClick = e => {
        e.preventDefault();
        this.props.open_searchBar(true);
    }

    saveLocation = hash => {
        this.setState({
            nowLocation: hash
        })
    }

    componentDidMount() {
        this.saveLocation(history.location.hash);
        history.listen(location => {
            if (this.state.nowLocation === location.hash) return;
            this.saveLocation(location.hash);
        });
    }


    render() {
        const { nowLocation } = this.state;
        const showSearchBtn = nowLocation.indexOf('#/product') >= 0 || nowLocation.indexOf('#/product_detail') >= 0;
        return (
            <header className="header">
                <div className="l__container">
                    <div className="header__body">
                        <button
                            className={`header__openMenu ${!this.state.openMenu ? '' : 'header__openMenu--active'}`}
                            onClick={this.openMenuClick}></button>
                        <h1>
                            <Link to="/" className="header__logo">宇瑩實業股份有限公司</Link>
                        </h1>
                        {
                            !showSearchBtn ?
                                null :
                                <button className="header__openSearch" onClick={this.openSearchClick}></button>
                        }
                        <NavBar openMenu={this.state.openMenu} openMenuClick={this.openMenuClick} />
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { productRoute } = settings;
    return { productRoute }
}

export default connect(mapStateToProps, { open_searchBar })(HeaderPage);