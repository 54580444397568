import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import _ from 'lodash';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../util/IntlMessages';


class BannerSwiper extends Component {
    next = () => {
        this.slider.slickNext();
    }
    previous = () => {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        const { locale, banner } = this.props;
        return (
            <div>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        _.map(banner[locale.locale], item => {
                            return (
                                <div key={item.ID}>
                                    <div style={{ position: 'relative' }}>
                                        <img src={item.Image_PC} alt="" className="img__default" alt={item.Text} />
                                        <img src={item.Image_M} alt="" className="img__mobile" alt={item.Text} />
                                        <div className="indexBanner__content">
                                            <div className="l__container">
                                                <p className="indexBanner__title" dangerouslySetInnerHTML={{ __html: `${item.Text}` }} />
            					{
							(item.Link.indexOf("http") >= 0 || item.Link.indexOf("https") >= 0)
								? <div className="l__moreLinkBtn" onClick={()=>window.open(item.Link)} style={{ 'cursor': 'pointer' }}>
									{<IntlMessages id="index.banner.link" />}
		                                                </div>
								:  <Link to={item.Link} className="l__moreLinkBtn">
                                                    			{<IntlMessages id="index.banner.link" />}
		                                                  </Link>

						}
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                <div className="indexBanner__ctlBtn">
                    <div className="l__container">
                        <button className="swiper-button-prev" onClick={this.previous}></button>
                        <button className="swiper-button-next" onClick={this.next}></button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { locale } = settings;
    return { locale }
}

export default connect(
    mapStateToProps,
    null
)(BannerSwiper);