import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swiper from 'react-id-swiper';
import "react-image-gallery/styles/css/image-gallery.css";
import _ from 'lodash';
import ReactLoading from "react-loading";

import IntlMessages from '../../util/IntlMessages';
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import ProductDetail from '../Product/DetailPage';
import BannerSwiper from './components/BannerSwiper';

import Pla_process from '../../assets/images/pc/pla_process.png';
import CornPC from '../../assets/images/pc/image_corn_layout.png';

import productCategory from '../../data/productCategory.json';

import { apiGetBannerList, apiGetProductHomeList, apiGetCertificateHomeList } from '../../apis'

class Home extends Component {
    state = {
        data: {}
    }

    componentDidMount() {
        this.fetchHomeApi();
    }

    fetchHomeApi = () => {
        axios.all([
            apiGetBannerList(),
            apiGetCertificateHomeList(),
            apiGetProductHomeList()
        ]).then(axios.spread((...res) => {
            const resArr = _.map(res, 'data');
            this.setState({
                data: {
                    banner: resArr[0].Body,
                    certificate: resArr[1].Body,
                    product: resArr[2].Body
                }
            })
        })).catch(err => {
            console.log(err)
        })
    }

    renderBanner() {
        const { data } = this.state;
        if (!data.banner) return <div className="loading" style={{ padding: '130px 0' }}>
            <ReactLoading type="bubbles" color="#b06b0d" />
        </div>

        return <BannerSwiper banner={data.banner} />
    }

    renderCertificate() {
        const { data } = this.state;
        const { width, locale } = this.props;
        const indexCertificateParams = {
            slidesPerView: 4,
            spaceBetween: 15,
            slidesPerGroup: 4,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        }

        if (!data.certificate) return <div className="loading" style={{ padding: '100px 0' }}>
            <ReactLoading type="bubbles" color="#b06b0d" />
        </div>

        return width > 1023 ? <div className="indexCertificate__slide">
            <Swiper {...indexCertificateParams}>
                {
                    _.map(data.certificate[locale.locale], item => {
                        return (
                            <div key={item.ID}>
                                <div className="indexCertificate__item">
                                    <img src={item.Image} alt={item.Name} />
                                    {/* <img src={images[item.Image]} alt="" /> */}
                                    <div className="indexCertificate__name">{item.Name}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </Swiper>
        </div> : <div className="indexCertificate__slide">
                <div className="row">
                    {
                        _.map(data.certificate[locale.locale], (item, idx) => {
                            if (idx <= 3) {
                                return (
                                    <div className="col-6" key={item.ID}>
                                        <img src={item.Image} alt={item.Name} />
                                        <div className="indexCertificate__name">{item.Name}</div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
    }

    renderProduct() {
        const { data } = this.state;
        const { locale } = this.props;

        if (!data.certificate) return <div className="loading" style={{ padding: '100px 0' }}>
            <ReactLoading type="bubbles" color="#b06b0d" />
        </div>

        return _.map(data.product[locale.locale], item => {
            const description = _.filter(productCategory[locale.locale], item2 => {
                return item2.Catalog === item.Catalog
            })
            return (
                <div className="col-6 col-lg-4" key={item.ID}>
                    <Link
                        className="indexProducts__item"
                        to={`product_detail/${item.ID}`}
                        component={ProductDetail}>
                        <img src={item.Image1} alt={item.Name} />
                        {/* <img src={productExportsFile()[item.Image1]} alt={item.Name} /> */}
                        <div className="indexProducts__content">
                            <div className="indexProducts__title">
                                {`${item.Name}`}
                                {/* {`${item.Catalog === "PAPER" ? '' : item.Catalog + ' '}${item.Name}`} */}
                            </div>
                            <p className="indexProducts__text" dangerouslySetInnerHTML={{ __html: `${item.Catalog_Description}` }}></p>
                            {/* <p className="indexProducts__text">{description[0].description}</p> */}
                            {/* <p className="indexProducts__text" dangerouslySetInnerHTML={{ __html: `${description[0].description}` }} /> */}
                        </div>
                    </Link>
                </div>
            )
        })
    }

    render() {
        return (
            <Fragment>
                <section>
                    <div className="indexBanner">
                        {this.renderBanner()}
                    </div>
                </section>
                <section>
                    <div className="l__container">
                        <div className="indexPlants">
                            <img src={Pla_process} alt="" />
                        </div>
                    </div>
                </section>
                <section>
                    <div className="indexMission">
                        <div className="l__container">
                            <div className="indexMission__title">
                                {<IntlMessages id="index.p1.title" />}
                            </div>
                            <p className="indexMission__text">
                                {<IntlMessages id="index.p1.content" />}
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="l__container">
                        <div className="indexCertificate">
                            <SectionTitle
                                title={<IntlMessages id="index.p2.title" />}
                                subTitle={<IntlMessages id="index.p2.subtitle" />}
                            />
                            {this.renderCertificate()}
                            <Link to="/certification" className="l__moreLinkBtn m-auto">
                                {<IntlMessages id="index.p2.link" />}
                            </Link>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="indexStraw">
                        <div className="l__container">
                            <img src={CornPC} alt="" />
                        </div>
                    </div>
                </section>
                <section>
                    <div className="l__container">
                        <div className="indexProducts">
                            <SectionTitle
                                title={<IntlMessages id="index.p3.title" />}
                                subTitle={<IntlMessages id="index.p3.subtitle" />}
                            />
                            <div className="indexProducts__list">
                                <div className="row">
                                    {this.renderProduct()}
                                </div>
                            </div>
                            <Link to="/product" className="l__moreLinkBtn m-auto">
                                {<IntlMessages id="index.p3.link" />}
                            </Link>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ settings }) => {
    const { width, locale } = settings;
    return { width, locale }
}

export default connect(
    mapStateToProps,
    null
)(Home);
