import {
    SWITCH_LANGUAGE,
    OPEN_ALERTPOPUP,
    OPEN_PDFPOPUP,
    WINDOW_WIDTH,
    OPEN_SEARCHBAR,
    CHANGE_PRODUCT_TAB,
    SET_META
} from '../constants/ActionTypes';

const webDomain = window.location.origin;
const webHref = window.location.href;
const metaDefault = {
    "EN": [{
        url: webHref,
        title: "V-IN Industrial Corp. PLA straw, drinking straw, single use straw, eco straw, friendly straw, alternative straw, straws, paper straw, plastic straw, biodegradable straw, compostable straw, nature works, marine degradable, made in tawian, USDA approved straw, FDA food grade straw, food-safe straw, party straw, daily use straw, kraft paper straw",
        description: "certificated by BRC, ISO 22000, HACCP, ISO 14064 and USDA  Customize your compostable straws Made from 100% renewable resources Meet ASTM standards for compostability Certified by BRC global food safety standard, Certificate# 1650578 Certified by HACCP and ISO 22000 international standards Biodegradable straws, Paper straws, Flexible straws, Boba straws, Paper wrapped straws, Plastic straws, Flat-cut/Angle-cut straws, Coffee stir straws, etc. In addition to general specifications, we also welcome customized products. meet the international green environmental protection trend for the longer-term future of the earth, we must keep pace with the times and replace petroleum materials with bio-based materials. Pursue self-breakthrough and growth, and at the same time let colleagues give self-affirmation in their work, in order to create a future that belongs to 'V-IN'',description: ' PLA straw, drinking straw, single use straw, eco straw, friendly straw, alternative straw, straws, paper straw, plastic straw, biodegradable straw, compostable straw, nature works, marine degradable, made in tawian, USDA approved straw, FDA food grade straw, food-safe straw, party straw, daily use straw, kraft paper straw",
        image: webDomain + require("../assets/images/pc/vin_logo.svg")
    }],
    "CH": [{
        url: webHref,
        title: "宇瑩實業股份有限公司 PLA吸管 生物可分解吸管 環保吸管 綠色吸管 可堆肥 聚乳酸PLA PP吸管 紙吸管 國際認證 工廠認證 環保認證 環保標章 生質標章 台灣製造 美國原料 台灣第一大廠 吸管",
        description: "不含5P﹑塑化劑﹑雙酚Ａ, 環境友善材質，在適當的溫度及濕度下可分解，不造成生態環境的負擔 安全無虞，完全符合FDA及國內食品安全法規，環保標章 由食品級PP材料製成，不含PBA，無鉛和不含重金屬，一次性使用過一根吸管 單支包裝 使用食品級紙漿製成 不軟化、不剝離分層，用畢後可做為紙類回收，或被大自然分解 無塑、無淋膜、無螢光添加, 品質保證, 最高等級, 最優良的品質、完善及即時的服務, 品質第一優先, 綠色循環經濟",
        image: webDomain + require("../assets/images/pc/vin_logo.svg")
    }]
}

const initialSettings = {
    width: window.innerWidth,
    alertOpen: false,
    openPdfPopup: false,
    searchBarOpen: false,
    productTabCurrent: "PLA",
    locale: {
        locale: 'EN',
        name: 'English',
    },
    meta: metaDefault["EN"][0]
};

const Settings = (state = initialSettings, action) => {
    switch (action.type) {
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width,
            };
        case OPEN_ALERTPOPUP:
            return {
                ...state,
                alertOpen: action.payload,
            };
        case OPEN_PDFPOPUP:
            return {
                ...state,
                openPdfPopup: action.payload,
            };
        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,
            };
        case OPEN_SEARCHBAR:
            return {
                ...state,
                searchBarOpen: action.payload,
            };
        case CHANGE_PRODUCT_TAB:
            return {
                ...state,
                productTabCurrent: action.payload,
            };
        case SET_META:
            const { locale } = state;
            const { url, title, description, image } = action.payload;
            return {
                ...state,
                meta: {
                    url: !url ? metaDefault[locale.locale][0].url : url,
                    title: !title ? metaDefault[locale.locale][0].title : title,
                    description: !description ? metaDefault[locale.locale][0].description : description,
                    image: !image ? metaDefault[locale.locale][0].image : image
                }
            };
        default:
            return state;
    }
};

export default Settings;