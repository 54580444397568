import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import _ from 'lodash';
import { apiContactUs } from '../../apis';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

import SectionTitle from '../../components/SectionTitle/SectionTitle';
import AlertMessage from './components/AlertMessage';

import countryData from '../../data/country.json';

class Contactus extends Component {
    state = {
        alertMessageOpen: false,
        isSuccess: false
    }

    renderError = ({ error, touched }) => {
        if (touched && error) {
            return (
                <p className="form__error">
                    {this.props.locale.locale === 'CH' ? error.CH : error.EN}
                </p>
            )
        }
    }

    renderInput = ({ name, input, label, placeholder, meta }) => {
        return (
            <div className="form__group">
                <label htmlFor={name}>{label}</label>
                <input id={name} type="text" {...input} autoComplete="off" placeholder={placeholder} />
                {this.renderError(meta)}
            </div>
        )
    }

    renderSelectField = ({ name, input, label = "", children, meta }) => {
        return (
            <div className="form__group">
                <label htmlFor={name}>{label}</label>
                <select name={name} {...input}>
                    {children}
                </select>
                {this.renderError(meta)}
            </div>
        )
    }

    renderTextField = ({ name, input, label, meta }) => {
        return (
            <div className="form__group">
                <label htmlFor={name}>{label}</label>
                <textarea className="form__group__comment" id={name} {...input}></textarea>
                {this.renderError(meta)}
            </div>
        )
    }

    onSubmit = (formValue) => {
        // console.log(formValue);
        apiContactUs({
            ...formValue,
            Telephone: formValue.Area + formValue.Telephone,
            Area: null
        }).then(res => {
            if (res.data.Message === "Success.") {
                this.setState({
                    alertMessageOpen: true,
                    isSuccess: true
                })
            } else {
                this.setState({
                    alertMessageOpen: true,
                    isSuccess: false
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                alertMessageOpen: true,
                isSuccess: false
            })
        })
    }

    closeAlert = () => {
        this.setState({
            alertMessageOpen: false
        })
    }

    render() {
        const { alertMessageOpen, isSuccess } = this.state;
        const { locale } = this.props.locale;
        return (
            <Fragment>
                {
                    !alertMessageOpen ?
                        null :
                        <AlertMessage IsSuccess={isSuccess} closeAlert={this.closeAlert} />
                }
                <Breadcrumbs router={<IntlMessages id="contactus.banner.title" />} />
                <section>
                    <div className="l__container l__container--page">
                        <Link to="/" className="l__backBtn d-lg-none">{<IntlMessages id="back.index" />}</Link>
                        <SectionTitle
                            title={<IntlMessages id="contactus.banner.title" />}
                            subTitle={<IntlMessages id="contact.subtitle" />}
                        />
                        <div className="contactus">
                            <form className="contactus__form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                <div className="form__row">
                                    <Field
                                        name="Last_Name"
                                        component={this.renderInput}
                                        label={<IntlMessages id="contact.last_name" />}
                                        placeholder={locale === "CH" ? "填入您的名字" : "Last Name"} />
                                    <Field
                                        name="First_Name"
                                        component={this.renderInput}
                                        label={<IntlMessages id="contact.first_name" />}
                                        placeholder={locale === "CH" ? "填入您的姓氏" : "First Name"} />
                                </div>
                                <Field
                                    name="Company"
                                    component={this.renderInput}
                                    label={<IntlMessages id="contact.compay" />}
                                    placeholder={locale === "CH" ? "填入公司名稱" : "Company name"} />
                                <Field
                                    name="Email"
                                    component={this.renderInput}
                                    label={<IntlMessages id="contact.email" />}
                                    placeholder={locale === "CH" ? "填入電子郵件" : "Email Address"} />
                                <div className="form__row form__row__areaTel">
                                    <Field name="Area" component={this.renderSelectField}>
                                        <option value="">{locale === "CH" ? "國家與地區" : "Country and Region"}</option>
                                        {
                                            _.map(countryData.userinfo_country_code, (item, idx) => {
                                                const num = _.filter(countryData.id_to_countrycode, (item2, idx2) => idx2 === idx);
                                                return (
                                                    <option
                                                        key={idx}
                                                        value={num}>
                                                        {locale === "CH" ? item : `${idx} (${num})`}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Field>
                                    <Field
                                        name="Telephone"
                                        component={this.renderInput}
                                        label={<IntlMessages id="contact.phone" />}
                                        placeholder={locale === "CH" ? "填寫電話號碼" : "Telephone No."} />
                                </div>
                                <Field name="Question" component={this.renderSelectField} label={<IntlMessages id="contact.subject" />} >
                                    <option value="">{locale === "CH" ? "問題類型" : "Subject"}</option>
                                    <option value={locale === "CH" ? "報價問題" : "Quote Inquiry"}>{locale === "CH" ? "報價問題" : "Quote Inquiry"}</option>
                                    <option value={locale === "CH" ? "產品詢問" : "Product"}>{locale === "CH" ? "產品詢問" : "Product"}</option>
                                    <option value={locale === "CH" ? "其他問題" : "Message"}>{locale === "CH" ? "其他問題" : "Message"}</option>
                                </Field>
                                <Field name="Comment" component={this.renderTextField} label={<IntlMessages id="contact.message" />} />
                                <button className="form__sendBtn">{<IntlMessages id="contact.submit" />}</button>
                            </form>
                            <div className="contactus__map">
                                <div className="contactus__map__title">{<IntlMessages id="contact.map" />}</div>
                                {
                                    locale === "CH" ?
                                        <iframe
                                            title="mapFrame"
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4950344775143!2d120.3107623149681!3d23.042305984943017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e7076237bc965%3A0x29851b586c6b027f!2zNzEy5Y-w5Y2X5biC5paw5YyW5Y2A5Lit5bGx6LevMTAwLTLomZ8!5e0!3m2!1szh-TW!2stw!4v1563293956943!5m2!1szh-TW!2stw"
                                            width="100%"
                                            height="400"
                                            frameBorder="0"
                                        /> : <iframe
                                            title="mapFrame"
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.494899929869!2d120.31076231519991!3d23.04231092137176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e7076237bc965%3A0x29851b586c6b027f!2zNzEy5Y-w5Y2X5biC5paw5YyW5Y2A5Lit5bGx6LevMTAwLTLomZ8!5e0!3m2!1sen-US!2stw!4v1563796348147!5m2!1sen-US!2stw"
                                            width="100%"
                                            height="400"
                                            frameBorder="0"
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const validate = (formValues) => {
    const error = {};
    const myReg = /[~!@#$%^&*()/[\]|,.<>?"'();:_+-=[\]{}]/;
    if (!formValues.Last_Name || !formValues.First_Name) {
        error.Last_Name = {
            'CH': '姓名不可空白',
            'EN': 'Required!'
        }
    } else if (myReg.test(formValues.Last_Name) || myReg.test(formValues.First_Name)) {
        error.Last_Name = {
            'CH': '不可包含特殊符號＊＆％$...等等',
            'EN': 'Do not contain special characters *&%$...!'
        }
    }

    if (!formValues.Company) {
        error.Company = {
            'CH': '公司名欄位不可空白',
            'EN': 'Required!'
        }
    }

    if (!formValues.Email) {
        error.Email = {
            'CH': '電子郵件欄位不可空白',
            'EN': 'Required!'
        }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.Email)) {
        error.Email = {
            'CH': '電子郵件欄位格式錯誤',
            'EN': 'Error!'
        }
    }

    if (!formValues.Area) {
        error.Telephone = {
            'CH': '請先選擇國家與地區',
            'EN': 'Please choose Country and region!'
        }
    } else {
        if (!formValues.Telephone) {
            error.Telephone = {
                'CH': '電話欄位不可空白',
                'EN': 'Required!'
            }
        } else if (!/^[0-9]+$/.test(formValues.Telephone)) {
            error.Telephone = {
                'CH': '電話欄位格式錯誤',
                'EN': 'Required!'
            }
        }
    }

    if (!formValues.Question) {
        error.Question = {
            'CH': '尚未選擇問題類型',
            'EN': 'Please choose subject!!'
        }
    }

    if (!formValues.Comment) {
        error.Comment = {
            'CH': '意見欄位不可空白',
            'EN': 'Do not leave blank'
        }
    }

    return error;
}

const mapStateToProps = ({ settings }) => {
    const { locale } = settings;
    return { locale }
}

export default reduxForm({
    form: 'contactus',
    validate
})(connect(mapStateToProps, null)(Contactus))