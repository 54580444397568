import React, { Component, Fragment } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import _ from 'lodash';
import IntlMessages from '../../util/IntlMessages';

// import BannerMaterialPC from '../../assets/images/pc/image_rural.png';
// import BannerMaterialM from '../../assets/images/mobile/image_rural_m.png';
import BannerAboutPC from '../../assets/images/pc/image_rural.png'
import BannerAboutM from '../../assets/images/mobile/image_rural_m.png'
import MaterialStep1 from '../../assets/images/pc/image_step1.png';
import MaterialStep2 from '../../assets/images/pc/image_step2.png';
import MaterialStep3 from '../../assets/images/pc/image_step3.png';
import MaterialPlaPC from '../../assets/images/pc/pla_process.png';
import MaterialPlaM from '../../assets/images/mobile/pla_process_1.png';
import MaterialDecomposition from '../../assets/images/pc/image_decomposition.png';
import MaterialDecompositionM from '../../assets/images/mobile/image_corn_1.png';
import MaterialNatureworksPC from '../../assets/images/pc/image_natureworks.png';
import MaterialNatureworksM from '../../assets/images/mobile/image_natureworks.png';

import AnimateText from './components/AnimateText';
import PageBanner from '../../components/PageBanner/PageBanner';


class Material extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs router={<IntlMessages id="material.banner.title" />} />
                <section>
                    <PageBanner
                        BannerAboutPC={BannerAboutPC}
                        BannerAboutM={BannerAboutM}
                        BannerTitle={<IntlMessages id="material.banner.title" />} />
                </section>
                {/* <section>
                    <div className="pageBanner materialBanner">
                        <div className="indexBanner__content materialBanner__banner">
                            <div className="l__container">
                                <img src={BannerMaterialPC} alt="" className="img__default" />
                                <img src={BannerMaterialM} alt="" className="img__mobile" />
                            </div>
                        </div>
                        <div className="indexBanner__content materialBanner__text">
                            <div className="l__container l__container--page">
                                <div className="pageBanner__title">
                                    {<IntlMessages id="material.banner.title" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section>
                    <div className="l__container l__container--page">
                        <div className="materialContent">
                            <div className="materialContent__item">
                                <div className="materialContent__title l__title">
                                    <IntlMessages id="material.p1.title" />
                                </div>
                                <AnimateText>
                                    <div className="materialContent__text">
                                        <p><IntlMessages id="material.p1.content" /></p>
                                        <div className="materialContent__table">
                                            <div className="materialContent__step1Table">
                                                <div className="materialContent__step1Table__item materialContent__step1Table__title">
                                                    <IntlMessages id="material.p1.table1.title" />
                                                </div>
                                                {
                                                    _.times(4, (n, idx) => {
                                                        return (
                                                            <div
                                                                key={n + 1}
                                                                className="materialContent__step1Table__item">
                                                                <IntlMessages id={`material.p1.table1.item${n + 1}`} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="materialContent__step1Table">
                                                <div className="materialContent__step1Table__item materialContent__step1Table__title">
                                                    <IntlMessages id="material.p1.table2.title" />
                                                </div>
                                                {
                                                    _.times(4, (n, idx) => {
                                                        return (
                                                            <div
                                                                key={n + 1}
                                                                className="materialContent__step1Table__item">
                                                                <IntlMessages id={`material.p1.table2.item${n + 1}`} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </AnimateText>
                            </div>
                            <div className="materialContent__item">
                                <div className="materialContent__title l__title">
                                    <IntlMessages id="material.p2.title" />
                                </div>
                                <AnimateText>
                                    <div className="materialContent__text">
                                        <p>
                                            <IntlMessages id="material.p2.content" />
                                        </p>
                                    </div>
                                </AnimateText>
                            </div>
                            <div className="materialContent__item">
                                <div className="materialContent__title l__title">
                                    <IntlMessages id="material.p3.title" />
                                </div>
                                <AnimateText>
                                    <div className="materialContent__text">
                                        <p>
                                            <IntlMessages id="material.p3.content" />
                                        </p>
                                        <div className="materialContent__step3">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="materialContent__step3__item">
                                                        <img src={MaterialStep1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="materialContent__step3__item">
                                                        <img src={MaterialStep2} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="materialContent__step3__item">
                                                        <img src={MaterialStep3} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img src={MaterialPlaPC} alt="" className="img__default" />
                                        <img src={MaterialPlaM} alt="" className="img__mobile" />
                                    </div>
                                </AnimateText>
                            </div>
                            <div className="materialContent__item">
                                <div className="materialContent__title l__title">
                                    <IntlMessages id="material.p4.title" />
                                </div>
                                <AnimateText>
                                    <div className="materialContent__text">
                                        <p>
                                            <IntlMessages id="material.p4.content" />
                                        </p>
                                        <img src={MaterialDecomposition} alt="" className="img__default" />
                                        <img src={MaterialDecompositionM} alt="" className="img__mobile" />
                                    </div>
                                </AnimateText>
                            </div>
                            <div className="materialContent__item">
                                <div className="materialContent__title l__title">
                                    <IntlMessages id="material.p5.title" />
                                </div>
                                <AnimateText>
                                    <div className="materialContent__text">
                                        <p><IntlMessages id="material.p5.content" /></p>
                                        <img src={MaterialNatureworksPC} alt="" className="img__default" />
                                        <img src={MaterialNatureworksM} alt="" className="img__mobile" />
                                    </div>
                                </AnimateText>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default Material
