import React, { Component } from 'react';
import { connect } from 'react-redux';
import { open_pdfpopup } from '../../actions';

class AlertPopupPDF extends Component {
    render() {
        const { titleName, children, open_pdfpopup } = this.props;
        return (
            <div className="alertPdf">
                <div className="alertPdf__header">
                    <div className="l__container">
                        <h4>{titleName}</h4>
                        <button
                            className="cd-popup-close cd-popup-close-white"
                            onClick={() => { open_pdfpopup(false) }}
                        ></button>
                    </div>
                </div>
                <div className="alertPdf__body">
                    <div className="l__container">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(null, { open_pdfpopup })(AlertPopupPDF);