import React from 'react'

const PageBanner = props => {
    const { BannerAboutPC, BannerAboutM, BannerTitle } = props;
    return (
        <div className="pageBanner">
            <img src={BannerAboutPC} alt="" className="img__default" />
            <img src={BannerAboutM} alt="" className="img__mobile" />
            <div className="indexBanner__content">
                <div className="l__container l__container--page">
                    <div className="pageBanner__title">{BannerTitle}</div>
                </div>
            </div>
        </div>
    )
}

export default PageBanner
