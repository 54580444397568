import React, { Fragment } from 'react'

const ListItem = ({ title, date }) => {
    return (
        <Fragment>
            <span className="newsContent__title">{title}</span>
            <span className="newsContent__date">{date}</span>
        </Fragment>
    )
}

export default ListItem
