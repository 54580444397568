import {
    WINDOW_WIDTH,
    SWITCH_LANGUAGE,
    OPEN_ALERTPOPUP,
    OPEN_PDFPOPUP,
    OPEN_SEARCHBAR,
    CHANGE_PRODUCT_TAB,
    SET_META
} from '../constants/ActionTypes';

export function updateWindowWidth(width) {
    return { type: WINDOW_WIDTH, width };
}

export function switch_language(locale) {
    return {
        type: SWITCH_LANGUAGE,
        payload: locale,
    };
}

export function open_alertpopup(value) {
    return {
        type: OPEN_ALERTPOPUP,
        payload: value,
    };
}

export function open_pdfpopup(value) {
    return {
        type: OPEN_PDFPOPUP,
        payload: value,
    };
}

export function open_searchBar(value) {
    return {
        type: OPEN_SEARCHBAR,
        payload: value,
    };
}

export function change_product_tab(value) {
    return {
        type: CHANGE_PRODUCT_TAB,
        payload: value,
    };
}

export function set_meta(value) {
    return {
        type: SET_META,
        payload: value,
    };
}