import React, { Component } from 'react';
import { connect } from 'react-redux';

import { switch_language } from '../../actions';

class LangSelect extends Component {
    state = {
        defaultLn: false,
        langOpen: false
    }

    componentDidMount() {
        this.setState({ defaultLn: true })
    }

    openLangClick = () => {
        this.setState({
            langOpen: !this.state.langOpen
        })
    }

    changeLangClick = (type) => {
        this.setState({ defaultLn: false })
        if (type === "CH") {
            this.props.switch_language({
                locale: 'CH',
                name: '中文（繁體)',
            })
        } else {
            this.props.switch_language({
                locale: 'EN',
                name: 'English',
            })
        }
    }

    render() {
        const { locale } = this.props;
        const langChange = `nav__lang__menu ${this.state.langOpen ? 'nav__lang__menu--open' : ''}`;
        const langChangeIcon = `nav__lang__text ${this.state.langOpen ? 'nav__lang__text--active' : ''}`;
        return (
            <div className="nav__lang" onClick={this.openLangClick}>
                <span className={langChangeIcon}>{this.state.defaultLn ? "Language" : locale.name}</span>
                <div className={langChange}>
                    <span className="nav__lang__item">
                        Language
                    </span>
                    <span
                        className={`nav__lang__item ${locale.locale === 'CH' && !this.state.defaultLn ? 'nav__lang__item--active' : ''}`}
                        onClick={() => this.changeLangClick('CH')}>
                        中文（繁體)
                    </span>
                    <span
                        className={`nav__lang__item ${locale.locale === 'EN' && !this.state.defaultLn ? 'nav__lang__item--active' : ''}`}
                        onClick={() => this.changeLangClick('EN')}>
                        English
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { locale } = settings;
    return { locale }
}

export default connect(
    mapStateToProps,
    { switch_language }
)(LangSelect);