import React from 'react'
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom'
import _ from 'lodash';
import ProductDetail from '../../pages/Product/DetailPage';

const SwiperList = props => {
    const { products } = props;
    const hotProductParams = {
        slidesPerView: 4,
        slidesPerGroup: 4,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 20,
        breakpoints: {
            1023: {
                slidesPerView: 2,
                slidesPerGroup: 1,
                spaceBetween: 15,
            }
        }
    }
    return (
        <Swiper {...hotProductParams}>
            {
                _.map(products, item => {
                    return (
                        <div key={item.ID}>
                            <Link to={`/product_detail/${item.Name}`} component={ProductDetail}>
                                <div className="indexCertificate__item">
                                    <img src={item.Image1} alt={item.Name} />
                                    <div className="indexCertificate__name">
                                        {item.Name}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }

        </Swiper>
    )
}

export default SwiperList
