import React, { Fragment } from 'react'

const SectionTitle = ({ title, subTitle }) => {
    const mb24 = {
        marginBottom: '24px'
    }
    return (
        <Fragment>
            <h3 className="l__title l__font36" style={mb24}>{title}</h3>
            {!subTitle ? null : <p className="l__subTitle">{subTitle}</p>}
        </Fragment>
    )
}

export default SectionTitle
