import React from 'react'
import { Link } from 'react-router-dom';
import _ from 'lodash';
import LangSelect from '../LangSelect/LangSelect';
import IntlMessages from '../../util/IntlMessages';


const FooterPage = () => {
    const urlName1 = ['/about', '/certification', '/news', '/contactus'];
    const urlName2 = ['/product', '/material'];
    return (
        <footer className="footer">
            <div className="footer__body">
                <div className="l__container">
                    <div className="footer__logoInfo">
                        <Link to="/" className="footer__logo"></Link>
                        <ul className="footer__brandInfo">
                            {
                                _.times(5, (n) => {
                                    return (
                                        <li
                                            key={n + 1}
                                            className={`footer__brandInfo__item ${n === 0 ? 'footer__title' : ''}`}>
                                            {<IntlMessages id={`layout.footer.company_info${n + 1}`} />}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="footer__navMenu">
                        <ul className="footer__menu">
                            <li className="footer__menu__item footer__title">
                                {<IntlMessages id="layout.footer.company_link.title" />}
                            </li>
                            {
                                _.times(4, (n) => {
                                    return (
                                        <li
                                            key={n + 1}
                                            className="footer__menu__item">
                                            <Link to={urlName1[n]} className="footer__menu__link">
                                                {<IntlMessages id={`layout.footer.company_link${n + 1}`} />}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <ul className="footer__menu">
                            <li className="footer__menu__item footer__title">
                                {<IntlMessages id="layout.footer.product_link.title" />}
                            </li>
                            {
                                _.times(2, (n) => {
                                    return (
                                        <li
                                            key={n + 1}
                                            className="footer__menu__item">
                                            <Link to={urlName2[n]} className="footer__menu__link">
                                                {<IntlMessages id={`layout.footer.product_link${n + 1}`} />}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <LangSelect />
                    </div>
                </div>
            </div>
            <div className="footer__bottom">
                <div className="l__container">
                    <p className="footer__copyright"> {<IntlMessages id="layout.footer.copyright" />}</p>
                </div>
            </div>
        </footer>
    )
}

export default FooterPage
