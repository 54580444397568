import axios from 'axios';
const cors = 'https://cors-anywhere.herokuapp.com/';

const webDomain = window.location.origin;
const frontendRequest = axios.create({
    // baseURL: `${webDomain}/v-in/api/frontend`
    baseURL: '/v-in/api/frontend'
        // baseURL: 'http://vin-straw.com.tw/v-in/api/frontend'
})
export const apiContactUs = data => frontendRequest.post('/Contact_Us.php/ADD', data);

export const apiGetBannerList = () => frontendRequest.get(`/Banner.php/LIST`);
export const apiGetProductHomeList = () => frontendRequest.get(`/Product.php/HOMELIST`);
export const apiGetProductHotList = () => frontendRequest.get(`/Product.php/HOTLIST`);
export const apiGetCertificateHomeList = () => frontendRequest.get(`/Certificate.php/HOMELIST`);

export const apiGetProductCatalog = () => frontendRequest.get(`/Catalog.php/PRODUCT`);
export const apiGetProductList = () => frontendRequest.get(`/Product.php/LIST`);
export const apiGetProductOne = data => frontendRequest.get(`/Product.php/ONE/${data}`);
export const apiGetProductSearch = data => frontendRequest.post('/Product.php/SEARCH', data);

export const apiGetCertificateCatalog = () => frontendRequest.get(`/Catalog.php/CERTIFICATE`);
export const apiGetCertificateList = () => frontendRequest.get(`/Certificate.php/LIST`);

export const apiGetNewList = () => frontendRequest.get(`/News.php/LIST`);
export const apiGetNewOne = data => frontendRequest.get(`/News.php/ONE/${data}`);