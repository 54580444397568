import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';


class Breadcrumbs extends Component {
    render() {
        const { router } = this.props;
        return (
            <div className="breadcrumbs">
                <div className="l__container">
                    <ul className="breadcrumbs__list">
                        <li className="breadcrumbs__item">
                            <Link to="/" className="breadcrumbs__link">{<IntlMessages id="layout.navigation1" />}</Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--active">{router}</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Breadcrumbs;