import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import _ from 'lodash';

const GalleryThumbs = props => {
    const { productImg, prouctGallery } = props;
    const images = _.map(productImg, item => {
        return {
            original: item,
            thumbnail: item
        }
    })
    const gallerySettings = {
        items: images,
        ...prouctGallery
    }
    return (
        <ImageGallery {...gallerySettings} onClick={(e) => { props.openProductImgPopup() }} />
    );

}

export default GalleryThumbs;