import React from 'react'
import PDFIcon from '../../../assets/images/pc/icon_pdf.png';
import IntlMessages from '../../../util/IntlMessages';


const ListItem = ({ img, title, number, deadline, pdf }) => {
    return (
        <div className="certificationContent__picList__item">
            <div className="certificationContent__picList__header">
                <img className="certificationContent__picList__img" src={img} alt={title} />
                {!pdf ? null : <img className="certificationContent__picList__pdf" src={PDFIcon} alt="" />}
            </div>
            <div className="certificationContent__picList__info">
                <div className="certificationContent__picList__name">
                    {title}
                    {!number ? null : <p>{<IntlMessages id="certification.registration" />} {number}</p>}
                </div>
                <span className="certificationContent__picList__deadline">
                    {!deadline ? null : <p>{<IntlMessages id="certification.Valid" />} {deadline}</p>}
                </span>
            </div>
        </div>
    )
}

export default ListItem
