import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { open_searchBar } from '../../actions';

class SearchBar extends Component {
    state = {
        searchText: ''
    }

    closeReSearchClick = e => {
        e.preventDefault();
        if (this.props.width < 1023) {
            this.props.open_searchBar(false);
        }
        this.setState({
            searchText: ''
        }, () => {
            this.props.history.push('/product');
        })
    }

    handlerInputChange = ({ target }) => {
        this.setState({
            searchText: target.value
        })
    }

    keyPress = e => {
        if (e.target.value === "") return;
        if (e.keyCode === 13) {
            this.props.history.push(!this.state.searchText ? this.props.match.url : `/search/${this.state.searchText}`);
        }
    }

    render() {
        const { searchBarOpen, locale, width, match } = this.props;
        const searchClassName = `searchBar ${searchBarOpen ? 'searchBar--show' : ''}`;
        return (
            <div className={searchClassName}>
                <div className="l__container">
                    <div className="searchBar__body">
                        <input type="text"
                            value={this.state.searchText}
                            placeholder={locale.locale === "CH" ? '尋找什麼產品呢' : 'Search'}
                            className="searchBar__keyword"
                            onChange={e => { this.handlerInputChange(e) }}
                            onKeyDown={this.keyPress}
                        />
                        {
                            width < 1023 ? <button className="searchBar__close" onClick={this.closeReSearchClick}></button> :
                                !this.state.searchText ? null : <button className="searchBar__close" onClick={this.closeReSearchClick}></button>
                        }
                        <Link
                            to={!this.state.searchText ? match.url : `/search/${this.state.searchText}`}
                            className="searchBar__searchBtn"></Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { searchBarOpen, locale, width } = settings;
    return { searchBarOpen, locale, width };
}

export default connect(mapStateToProps, { open_searchBar })(SearchBar);
