import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfViewer extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const { numPages } = this.state;
        const { pdfFile, width } = this.props;
        const pdfWidth = width <= 595 ? width : 595;
        return (
            <Fragment>
                <Document
                    file={pdfFile}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                >
                    {
                        _.times(numPages, n => {
                            return <Page
                                pageNumber={n + 1}
                                key={n + 1}
                                width={pdfWidth - 30}
                            />
                        })
                    }
                </Document>
                {/* {pageNumber} */}
                <p className="pdfNumPage">共 {numPages} 頁</p>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    return {
        width: settings.width,
    }
}

export default connect(mapStateToProps, null)(PdfViewer);
