import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';

class AnimateText extends Component {
    state = {
        height: this.props.width < 1023 ? 40 : 'auto',
        isOpen: false
    }

    toggle = () => {
        this.setState({
            height: 'auto',
            isOpen: !this.state.isOpen
        });
    };

    render() {
        const { children, width } = this.props;
        const boxHeight = width < 1023 && !this.state.isOpen ? 40 : 'auto';
        const arrowIcon = !this.state.isOpen ? 'materialContent__showContent' : 'materialContent__showContent materialContent__showContent--active';
        return (
            <Fragment>
                <AnimateHeight
                    duration={1000}
                    height={boxHeight}>
                    {children}
                </AnimateHeight>
                {width < 1023 ? <button onClick={this.toggle} className={arrowIcon}></button> : null}
            </Fragment>
        )
    }
}

const mapStateToProps = ({ settings }) => {
    const { width } = settings;
    return { width }
}

export default connect(
    mapStateToProps,
    null
)(AnimateText);