import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import IntlMessages from '../../util/IntlMessages';
//<h1>{<IntlMessages id="index.demo" />}</h1>
import LangSelect from '../LangSelect/LangSelect'

const NavBarPage = ({ openMenu, openMenuClick, match }) => {
    const navClassName = `nav ${openMenu ? 'nav--show' : ''}`;
    const urlName = ['/', '/about', '/material', '/certification', '/product', '/news', '/contactus'];
    return (
        <ul className={navClassName} >
            {
                _.times(7, (n) => {
                    return (
                        <li className="nav__item" key={n + 1} onClick={e => { openMenuClick(e) }}>
                            <NavLink
                                to={urlName[n]}
                                exact
                                activeClassName="nav__link--active"
                                className="nav__link">
                                {<IntlMessages id={`layout.navigation${n + 1}`} />}
                            </NavLink>
                        </li>
                    )
                })
            }
            <li className="nav__item">
                <LangSelect />
            </li>
        </ul>

    )
}

export default NavBarPage      
