import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import IntlMessages from '../../util/IntlMessages';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PageBanner from '../../components/PageBanner/PageBanner';

import BannerAboutPC from '../../assets/images/pc/image_aboutus.png'
import BannerAboutM from '../../assets/images/mobile/image_aboutus_m.png'

import about from '../../assets/images/pc/about.png'

const About = () => {
    return (
        <Fragment>
            <Breadcrumbs router={<IntlMessages id="about.banner.title" />} />
            <section>
                <PageBanner
                    BannerAboutPC={BannerAboutPC}
                    BannerAboutM={BannerAboutM}
                    BannerTitle={<IntlMessages id="about.banner.title" />} />
            </section>
            <section>
                <div className="l__container l__container--page">
                    <Link to="/" className="l__backBtn d-block d-lg-none">{<IntlMessages id="back.index" />}</Link>
                    <div className="aboutContent">
                        <div className="aboutContent__item">
                            <div className="aboutContent__title l__title l__font24">
                                {<IntlMessages id="about.p1.title" />}
                            </div>
                            <div className="aboutContent__text">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p>{<IntlMessages id="about.p1.content" />}</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <img src={about} alt="" style={{ 'width': '100%', 'alignSelf': 'flex-start' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aboutContent__item">
                            <div className="aboutContent__title l__title l__font24">
                                {<IntlMessages id="about.p2.title" />}
                            </div>
                            <div className="aboutContent__text">
                                <p>{<IntlMessages id="about.p2.content" />}</p>
                                <ul className="aboutContent__list">
                                    {_.times(4, n => <li key={n + 1}> {<IntlMessages id={`about.p2.item${n + 1}`} />} </li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="aboutContent__item">
                            <div className="aboutContent__title l__title l__font24">
                                {<IntlMessages id="about.p3.title" />}
                            </div>
                            <div className="aboutContent__text">
                                <ul className="aboutContent__label">
                                    {_.times(3, n => <li key={n + 1}> {<IntlMessages id={`about.p3.item${n + 1}`} />} </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default About
