import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import App from './App';
// import PdfViewer from './components/PdfViewer/PdfViewer'
import reducers from './reducers';

const store = createStore(reducers);

render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.querySelector('#root'));

// render(<PdfViewer />, document.querySelector('#tt'));