import React from 'react'
import { connect } from 'react-redux';
import { open_alertpopup } from '../../actions'

const ListItem = (props) => {
    const { img, title, open_alertpopup, alertOpen, saveProductDetail } = props;
    return (
        <div className="productContent__list__item">
            <div className="productContent__list__img">
                <img src={img} alt="" />
                <button
                    className="productContent__list__search cd-popup-trigger"
                    onClick={() => {
                        open_alertpopup(!alertOpen);
                        saveProductDetail();
                    }}
                >
                </button>
            </div>
            <div className="productContent__list__info">
                <div className="productContent__list__name">{title}</div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ settings }) => {
    return {
        alertOpen: settings.alertOpen
    }
}

export default connect(mapStateToProps, { open_alertpopup })(ListItem);
