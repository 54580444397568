import enLang from './entries/en-US';
import zhLang from './entries/zh-TW';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  EN: enLang,
  CH: zhLang,
};
addLocaleData(AppLocale.EN.data);
addLocaleData(AppLocale.CH.data);

export default AppLocale;
